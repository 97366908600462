import React, { useState } from "react"
import { navigate } from "gatsby"
import Recaptcha from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import Axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../styles/pages/index.module.scss"

// IMAGES
import Hero from "../images/fallbacks/hero-old.jpg"
import HeroSmall from "../images/fallbacks/hero-old-small.jpg"
import WhyJengaOne from "../images/why-jenga-one.svg"
import WhyJengaTwo from "../images/why-jenga-two.svg"
import WhyJengaThree from "../images/why-jenga-three.svg"
import ClientTwo from "../images/logos/altaroad.png"
import ClientThree from "../images/logos/artefact.png"
import ClientFour from "../images/logos/koko.png"
import ClientFive from "../images/logos/looksgood.jpg"
import ClientSix from "../images/logos/unblocked.png"
import ClientSeven from "../images/logos/Medicillio-logo.svg"
import WorksOne from "../images/works-one.svg"
import WorksTwo from "../images/works-two.svg"
import WorksThree from "../images/works-three.svg"
import WavyLineThree from "../images/wavy-line-three.svg"
import WavyLineFour from "../images/wavy-line-four.svg"
import TickIcon from "../images/tick.svg"
import StackOne from "../images/stacks/javascript.png"
import StackTwo from "../images/stacks/react.png"
import StackThree from "../images/stacks/vue.svg"
import StackFour from "../images/stacks/angular.svg"
import StackFive from "../images/stacks/python.svg"
import StackSix from "../images/stacks/django.svg"
import StackSeven from "../images/stacks/flask.svg"
import StackEight from "../images/stacks/node.svg"
import StackNine from "../images/stacks/aws.png"
import StackTen from "../images/stacks/gcp.png"
import StackEleven from "../images/stacks/postgresql.svg"
import StackTwelve from "../images/stacks/mysql.svg"
import Cecile from "../images/cecile.jpg"
import Sameer from "../images/sameer.jpeg"
import ChatStack from "../images/chat-stack.svg"
import SourceTrain from "../images/source-train.svg"
import HireOnboard from "../images/hire-onboard.svg"
import Deliver from "../images/deliver.svg"

const IndexPage = () => {
  const { handleSubmit, register, errors } = useForm()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState(false)
  const [token, setToken] = useState("")

  const submitHandler = (values: any) => {
    console.log(values)
    setLoading(true)

    Axios.post(`${process.env.GATSBY_EMAIL_URL}/contact`, { ...values, token })
      .then((res: any) => {
        navigate("/success")
      })
      .catch(err => {
        console.log(err)

        if (err.response) {
          console.log(err.response)
        }

        setMessage(
          "We were unable to send your message. Please try again later."
        )
        setSuccess(false)
      })

    setLoading(false)
  }

  return (
    <Layout>
      <SEO
        title="Hire Kenya’s top tech talent"
        description="Hire Kenya’s top tech talent with Jenga. Access an untapped talent pool and recruit the best software engineers for your team."
        meta={[
          {
            name: "keywords",
            content:
              "jenga, jenga agency, apps, build websites, build apps, jenga apps, jenga websites",
          },
        ]}
      />
      <section className={styles.heroWrapper}>
        <picture className={styles.heroImgWrapper}>
          <source
            media="(max-width: 700px)"
            srcSet={HeroSmall}
            className={styles.heroImg}
          />

          <img
            src={Hero}
            alt="Jenga team working in the office"
            className={styles.heroImg}
          />
        </picture>
        <div className={styles.heroContent}>
          <h2 className={styles.heroTitle}>Hire Kenya’s Top Tech Talent</h2>
          <span className={styles.heroTitleBorder}></span>
          <p className={styles.heroSubtitle}>
            Augment your tech team &amp; Accelerate your growth with the top 1%
            of Kenya’s developers
          </p>
        </div>
      </section>

      <section className={styles.whyJengaWrapper}>
        <div className={styles.whyJenga}>
          <h2 className={styles.whyJengaTitle}>Why Jenga?</h2>
          <div className={styles.whyJengaCard}>
            <div className={styles.whyJengaCardImg}>
              <img
                src={WhyJengaOne}
                alt="Illustration of a lady and a gentleman working on their laptops"
              />
            </div>
            <div className={styles.whyJengaCardContent}>
              <h3 className={styles.whyJengaCardTitle}>
                Native English-Speaking Top Talent
              </h3>
              <ul>
                <li>
                  <div className={styles.whyJengaCardIcon}>
                    <svg
                      id="lightbulb"
                      xmlns="http://www.w3.org/2000/svg"
                      width="36.104"
                      height="49.385"
                      viewBox="0 0 36.104 49.385"
                    >
                      <path
                        id="Icon_awesome-lightbulb"
                        data-name="Icon awesome-lightbulb"
                        d="M9.853,43.824a2.958,2.958,0,0,0,.55,1.706l1.753,2.478a3.341,3.341,0,0,0,2.732,1.377h6.33a3.341,3.341,0,0,0,2.732-1.377L25.7,45.53a2.956,2.956,0,0,0,.55-1.706l0-3.7H9.848ZM0,16.976A16.316,16.316,0,0,0,4.468,28.143a27.364,27.364,0,0,1,5.355,8.821c0,.025.007.05.011.075H26.27c0-.025.007-.049.011-.075a27.365,27.365,0,0,1,5.355-8.821A16.316,16.316,0,0,0,36.1,16.976C36.1,7.582,27.992-.029,18,0,7.533.03,0,8,0,16.976ZM18.052,9.26a7.981,7.981,0,0,0-8.205,7.716,1.594,1.594,0,0,1-1.641,1.543,1.594,1.594,0,0,1-1.641-1.543c0-5.957,5.153-10.8,11.488-10.8a1.594,1.594,0,0,1,1.641,1.543A1.594,1.594,0,0,1,18.052,9.26Z"
                        transform="translate(0 0)"
                        fill="#f7ed8d"
                      />
                    </svg>
                  </div>
                  <span>Top 1% of Kenyan software engineers</span>
                </li>
                <li>
                  <div className={styles.whyJengaCardIcon}>
                    <svg
                      id="speak-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="52.407"
                      height="45.369"
                      viewBox="0 0 52.407 45.369"
                    >
                      <path
                        id="Path_628"
                        data-name="Path 628"
                        d="M4.461,15.743a15.134,15.134,0,0,1,4.889-11A16.594,16.594,0,0,1,20.642.141a16.945,16.945,0,0,1,9.444,2.976,15.2,15.2,0,0,1,5.9,7.51.726.726,0,0,0,.027.068c1.482,3.238.549,10.166-.2,15.732-.2,1.508-.4,2.933-.523,4.169,0,.024,0,.048,0,.072l-.1,14.842H12.818V39.655l7.486.1a.685.685,0,0,0,.641-.715.705.705,0,0,0-.2-.485.621.621,0,0,0-.465-.188L7.913,38.77a3.111,3.111,0,0,1-2.328-.956A3.54,3.54,0,0,1,4.6,35.373l-.077-8.2a.674.674,0,0,0-.635-.688s1.227.17-2.392,0S4.4,16.035,4.4,16.035A.733.733,0,0,0,4.461,15.743Zm0,0"
                        transform="translate(15.611 -0.141)"
                        fill="#f7ed8d"
                      />
                      <path
                        id="Path_629"
                        data-name="Path 629"
                        d="M362.888,259.5c.51.3.869,1.771.36,2.073-4.455,2.657-4.455,5.143,0,7.8.341.194.12,1.3,0,1.571a1.189,1.189,0,0,1-.935.551,1.954,1.954,0,0,1-1.27-.214c-5.466-3.261-5.466-8.519,0-11.78A2,2,0,0,1,362.888,259.5Zm0,0"
                        transform="translate(-350.973 -232.98)"
                        fill="#f7ed8d"
                      />
                      <path
                        id="Path_630"
                        data-name="Path 630"
                        d="M413.267,232.4c.448.3,1.289,1.651.841,1.953-6.81,4.575-7.126,10.083-.313,14.733.29.2-.121,1.461-.23,1.727a1.207,1.207,0,0,1-.82.7,1.552,1.552,0,0,1-1.109-.207c-7.695-5.252-7.692-13.733.007-18.9A1.574,1.574,0,0,1,413.267,232.4Zm.527,0"
                        transform="translate(-405.866 -209.372)"
                        fill="#f7ed8d"
                      />
                    </svg>
                  </div>
                  <span>Native English Speakers with neutral accents</span>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.whyJengaCardInverse}>
            <div className={styles.whyJengaCardContent}>
              <h3 className={styles.whyJengaCardTitle}>Flexibility</h3>
              <ul>
                <li>
                  <div className={styles.whyJengaCardIcon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46.338"
                      height="46.338"
                      viewBox="0 0 46.338 46.338"
                    >
                      <path
                        id="Icon_awesome-clock"
                        data-name="Icon awesome-clock"
                        d="M23.731.563A23.169,23.169,0,1,0,46.9,23.731,23.165,23.165,0,0,0,23.731.563ZM29.066,33.27l-8.24-5.988a1.128,1.128,0,0,1-.458-.906V10.652a1.124,1.124,0,0,1,1.121-1.121h4.484a1.124,1.124,0,0,1,1.121,1.121V23.517l5.932,4.316a1.12,1.12,0,0,1,.243,1.57l-2.635,3.625A1.129,1.129,0,0,1,29.066,33.27Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#f7ed8d"
                      />
                    </svg>
                  </div>

                  <span>
                    Full working hours overlap (EU) or adjusted working hours
                    (US/Canada)
                  </span>
                </li>
                <li>
                  <div className={styles.whyJengaCardIcon}>
                    <svg
                      id="add-person-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="58.772"
                      height="42.519"
                      viewBox="0 0 58.772 42.519"
                    >
                      <path
                        id="Icon_material-person-add"
                        data-name="Icon material-person-add"
                        d="M38.9,27.26A10.63,10.63,0,1,0,28.215,16.63,10.655,10.655,0,0,0,38.9,27.26ZM14.857,21.945V13.972H9.514v7.972H1.5V27.26H9.514v7.972h5.343V27.26h8.014V21.945ZM38.9,32.575c-7.133,0-21.372,3.561-21.372,10.63v5.315H60.272V43.2C60.272,36.136,46.033,32.575,38.9,32.575Z"
                        transform="translate(-1.5 -6)"
                        fill="#f7ed8d"
                      />
                    </svg>
                  </div>
                  <span>New developer joins within days</span>
                </li>
                <li>
                  <div className={styles.whyJengaCardIcon}>
                    <svg
                      id="payrate-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="49.246"
                      height="49.509"
                      viewBox="0 0 49.246 49.509"
                    >
                      <g
                        id="dollars-money-bag-with-a-clock"
                        transform="translate(31.816 5.927)"
                      >
                        <g id="money_time">
                          <g id="Group_218" data-name="Group 218">
                            <path
                              id="Path_632"
                              data-name="Path 632"
                              d="M25.67,15.883a1.912,1.912,0,0,0-.262-.4L23.732,5.791,21.957,15.6h.006a2.133,2.133,0,0,0-.411,1.264,2.186,2.186,0,0,0,2.181,2.185,2.1,2.1,0,0,0,.715-.132l0,.011,6.6.77-5.372-3.825Z"
                              transform="translate(-21.551 -5.791)"
                              fill="#f7ed8d"
                            />
                          </g>
                        </g>
                      </g>
                      <path
                        id="Path_631"
                        data-name="Path 631"
                        d="M35.039,3.493A14.172,14.172,0,0,0,27.3,5.8c.314-1.918.244-3.705-.55-4.813-2.679-3.713-7.164,4.357-9.224,3.532S11.07-.619,8.161,1.139c-2.519,1.519,1.58,11.721,3.56,13.2a31.419,31.419,0,0,0,6.329.761c1.171,0,2.187-.063,3.007-.142a14.12,14.12,0,0,0-.259,2.333c-.953.085-1.921.145-2.911.145a32.776,32.776,0,0,1-7.067-.761C6.907,19.834.056,31.08.056,35.981c0,8.84,7.774,13.528,17.368,13.528s17.363-4.688,17.363-13.526a13.365,13.365,0,0,0-.82-3.964c.354.029.709.054,1.07.054a14.29,14.29,0,0,0,0-28.58ZM22.844,38.737a3.631,3.631,0,0,1-.82,1.129,4.176,4.176,0,0,1-1.243.786,4.781,4.781,0,0,1-1.56.354v1.182H16.607V40.653c-.139-.063-.272-.121-.4-.178s-.259-.114-.383-.175v.577H13.208v-3.93h2.615a5.734,5.734,0,0,0,.664.647,3.283,3.283,0,0,0,.673.447,2.664,2.664,0,0,0,.763.263,4.855,4.855,0,0,0,.962.088,2.19,2.19,0,0,0,1.177-.291.865.865,0,0,0,.468-.758,1.022,1.022,0,0,0-.241-.679,2.076,2.076,0,0,0-.627-.5,3.925,3.925,0,0,0-.839-.326,8.828,8.828,0,0,0-.908-.2,8.056,8.056,0,0,1-2.54-.749,4.923,4.923,0,0,1-1.466-1.1,3.053,3.053,0,0,1-.673-1.227,4.609,4.609,0,0,1-.158-1.112,3.508,3.508,0,0,1,1-2.475,4.082,4.082,0,0,1,1.12-.825,4.475,4.475,0,0,1,1.412-.436V26.482h2.615v1.425a4.412,4.412,0,0,1,1.047.446v-.566h2.611v3.931H20.248c-.212-.234-.417-.447-.615-.628A3.03,3.03,0,0,0,19,30.644a3.182,3.182,0,0,0-.737-.274,4.132,4.132,0,0,0-.944-.1,1.744,1.744,0,0,0-1.2.374,1.078,1.078,0,0,0-.419.806c0,.808.869,1.348,2.615,1.611a7.369,7.369,0,0,1,2.585.8,4.908,4.908,0,0,1,1.466,1.2,3.251,3.251,0,0,1,.646,1.267,5.066,5.066,0,0,1,.139,1.021A3.313,3.313,0,0,1,22.844,38.737Zm13.383-9.99-1.191-1.42-1.192,1.42A11.031,11.031,0,0,1,24.1,19.023L25.674,17.7,24.126,16.4a11.026,11.026,0,0,1,9.748-9.575l1.161,1.385L36.2,6.821a11.035,11.035,0,0,1,9.748,9.564l-1.668,1.4,1.668,1.4A11.025,11.025,0,0,1,36.228,28.747Z"
                        transform="translate(-0.056 0)"
                        fill="#f7ed8d"
                      />
                    </svg>
                  </div>

                  <span>Pay as you go</span>
                </li>
              </ul>
            </div>
            <div className={styles.whyJengaCardImg}>
              <img
                src={WhyJengaTwo}
                alt="Illustration of a lady with her laptop and her coffee on a desk"
              />
            </div>
          </div>
          <div className={styles.whyJengaCard}>
            <div className={styles.whyJengaCardImg}>
              <img
                src={WhyJengaThree}
                alt="Illustration of a lady holding a credit card"
              />
            </div>

            <div className={styles.whyJengaCardContent}>
              <h3 className={styles.whyJengaCardTitle}>Cost</h3>
              <ul>
                <li>
                  <div className={styles.whyJengaCardIcon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45.254"
                      height="48.846"
                      viewBox="0 0 45.254 48.846"
                    >
                      <path
                        id="Icon_material-money-off"
                        data-name="Icon material-money-off"
                        d="M30.142,15.083c5.073,0,6.953,2.307,7.124,5.7h6.3c-.2-4.668-3.192-8.955-9.148-10.339V4.5H25.867v5.862a17.443,17.443,0,0,0-4.218,1.465l4.189,3.989A11.672,11.672,0,0,1,30.142,15.083ZM9.709,7.377,6.09,10.823l9.8,9.335c0,5.644,4.446,8.711,11.143,10.611l10,9.525c-.969,1.3-2.992,2.469-6.9,2.469-5.871,0-8.179-2.5-8.492-5.7H15.38c.342,5.943,5.016,9.281,10.487,10.393v5.889h8.549V47.512A14.589,14.589,0,0,0,41.4,44.473L47.725,50.5l3.619-3.446Z"
                        transform="translate(-6.09 -4.5)"
                        fill="#f7ed8d"
                      />
                    </svg>
                  </div>

                  <span>No recruiting fee</span>
                </li>
                <li>
                  <div className={styles.whyJengaCardIcon}>
                    <svg
                      id="money-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="55.436"
                      height="33.755"
                      viewBox="0 0 55.436 33.755"
                    >
                      <path
                        id="Icon_awesome-money-bill-alt"
                        data-name="Icon awesome-money-bill-alt"
                        d="M30.49,24.19H29.1V16.455a.7.7,0,0,0-.693-.7H27.235a2.054,2.054,0,0,0-1.153.354l-1.328.9a.709.709,0,0,0-.192.975l.769,1.17a.686.686,0,0,0,.961.2l.041-.027V24.19H24.946a.7.7,0,0,0-.693.7V26.3a.7.7,0,0,0,.693.7H30.49a.7.7,0,0,0,.693-.7V24.894A.7.7,0,0,0,30.49,24.19ZM52.664,4.5H2.772A2.793,2.793,0,0,0,0,7.313V35.442a2.793,2.793,0,0,0,2.772,2.813H52.664a2.793,2.793,0,0,0,2.772-2.813V7.313A2.793,2.793,0,0,0,52.664,4.5ZM4.158,34.036V28.41A5.585,5.585,0,0,1,9.7,34.036Zm0-19.69V8.719H9.7A5.585,5.585,0,0,1,4.158,14.345Zm23.56,16.878c-4.593,0-8.315-4.408-8.315-9.845s3.723-9.845,8.315-9.845,8.315,4.407,8.315,9.845S32.309,31.223,27.718,31.223Zm23.56,2.813H45.735a5.585,5.585,0,0,1,5.544-5.626Zm0-19.69a5.585,5.585,0,0,1-5.544-5.626h5.544Z"
                        transform="translate(0 -4.5)"
                        fill="#f7ed8d"
                      />
                    </svg>
                  </div>

                  <span>
                    Transparent, stable, and highly competitive daily rate
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.clients}>
        <h2 className={styles.clientsTitle}>They have grown with Jenga</h2>
        <div className={styles.clientsImgGrid}>
          <img
            src={ClientTwo}
            alt="Altaroad company logo"
            className={styles.clientsImg}
          />
          <img
            src={ClientThree}
            alt="Artefact company logo"
            className={styles.clientsImg}
          />
          <img
            src={ClientFour}
            alt="Koko networks company logo"
            className={styles.clientsImg}
          />
          <img
            src={ClientFive}
            alt="LooksGood AI company logo"
            className={styles.clientsImg}
          />
          <img
            src={ClientSeven}
            alt="Medicillio company logo"
            className={styles.clientsImg}
          />
          <img
            src={ClientSix}
            alt="UnblockData company logo"
            className={styles.clientsImg}
          />
        </div>
      </section>

      <section className={styles.works}>
        <h2 className={styles.worksTitle}>How it works</h2>

        <div className={styles.worksCard}>
          <div className={styles.worksCardImg}>
            <img
              src={WorksTwo}
              alt="illustration of a man wearing headphones typing on his laptop"
            />
          </div>
          <div className={styles.worksCardContent}>
            <h3 className={styles.worksCardTitle}>Who are Jenga Developers?</h3>
            <ul>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  <span className={styles.worksCardEmphasis}>
                    Top 1% talent
                  </span>{" "}
                  right after CS degree/education, eager to learn
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  <span className={styles.worksCardEmphasis}>
                    Thought leaders
                  </span>{" "}
                  – Expect questions and engagement, not blind execution
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  <span className={styles.worksCardEmphasis}>
                    Trained on communication
                  </span>{" "}
                  to work in a remote environment
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  Worked on projects and has{" "}
                  <span className={styles.worksCardEmphasis}>
                    1+ year(s) work experience
                  </span>
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  <span className={styles.worksCardEmphasis}>Expertise</span> in
                  either front-end and/or back-end
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.worksCardReverse}>
          <div className={styles.worksCardContent}>
            <h3 className={styles.worksCardTitle}>
              How to make the most out of Jenga developers
            </h3>
            <ul>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  <span className={styles.worksCardEmphasis}>
                    Have a Project Manager
                  </span>{" "}
                  to prioritize your workload
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  <span className={styles.worksCardEmphasis}>
                    Have a CTO/senior developer
                  </span>{" "}
                  to guide them initially in your stack, help them grow with you
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  <span className={styles.worksCardEmphasis}>
                    Ideally, have a full tech team
                  </span>{" "}
                  to fully leverage the talent
                </span>
              </li>
            </ul>
          </div>
          <div className={styles.worksCardImg}>
            <img
              src={WorksOne}
              alt="illustration of a man wearing headphones typing on his laptop"
            />
          </div>
        </div>
        <div className={styles.worksCard}>
          <div className={styles.worksCardImg}>
            <img
              src={WorksThree}
              alt="illustration of a man wearing headphones typing on his laptop"
            />
          </div>
          <div className={styles.worksCardContent}>
            <h3 className={styles.worksCardTitle}>
              You hired a Jenga Developer. Now what?
            </h3>
            <ul>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  <span className={styles.worksCardEmphasis}>
                    The Jenga developer fully integrates into your team
                  </span>
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  She/he{" "}
                  <span className={styles.worksCardEmphasis}>
                    has access to your tools
                  </span>{" "}
                  (e.g., Github, Slack, JIRA)
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  She/he{" "}
                  <span className={styles.worksCardEmphasis}>
                    works from the Jenga office
                  </span>{" "}
                  with Jenga hardware
                </span>
              </li>
              <li>
                <img src={TickIcon} alt="Tick Icon in yellow" />
                <span>
                  At the end of the month,{" "}
                  <span className={styles.worksCardEmphasis}>
                    you pay per day worked
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className={styles.stack}>
        <div className={styles.stackCardGrid}>
          <div className={styles.stackCard}>
            <img
              src={StackOne}
              alt="Javascript Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>Javascript</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackTwo}
              alt="React Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>React</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackThree}
              alt="Vue Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>Vue</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackFour}
              alt="Angular Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>Angular</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackFive}
              alt="Python Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>Python</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackSix}
              alt="Django Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>Django</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackSeven}
              alt="Flask Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>Flask</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackEight}
              alt="Node Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>Node</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackNine}
              alt="Amazon Web Services Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>AWS</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackTen}
              alt="Google cloud platform Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>GCP</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackEleven}
              alt="Postgresql Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>Postgresql</h3>
          </div>
          <div className={styles.stackCard}>
            <img
              src={StackTwelve}
              alt="my sequal Logo"
              className={styles.stackCardImg}
            />
            <h3 className={styles.stackCardTitle}>MySQL</h3>
          </div>
        </div>
        <div className={styles.stackContent}>
          <h2 className={styles.stackTitle}>Our Stack</h2>
          <p className={styles.stackIntro}>
            Our software engineers are proficient with the latest technologies,
            whether front-end, back-end and DevOps. Based on your stack, we find
            the best skills match to fit your needs.
          </p>
        </div>
      </section>

      <section className={styles.testimonialsWrapper}>
        <div className={styles.testimonials}>
          <h2 className={styles.testimonialsTitle}>What our clients say</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="27.88"
            viewBox="0 0 30 27.88"
            className={styles.testimonialsIcon}
          >
            <g>
              <path
                fill="#fff"
                d="M12.917 13.562v5.006a1.229 1.229 0 0 1-1.229 1.232c-2.422 0-3.74 2.484-3.923 7.388h3.923a1.229 1.229 0 0 1 1.229 1.229v10.57a1.229 1.229 0 0 1-1.229 1.229H1.229A1.229 1.229 0 0 1 0 38.984v-10.57A27.106 27.106 0 0 1 .7 22a15.428 15.428 0 0 1 2.183-5.075A10.655 10.655 0 0 1 6.6 13.538a10.557 10.557 0 0 1 5.093-1.205 1.229 1.229 0 0 1 1.224 1.229zM28.771 19.8A1.23 1.23 0 0 0 30 18.568v-5.006a1.229 1.229 0 0 0-1.229-1.229 10.209 10.209 0 0 0-8.8 4.595A15.435 15.435 0 0 0 17.786 22a27.126 27.126 0 0 0-.7 6.412v10.57a1.229 1.229 0 0 0 1.229 1.229h10.456A1.229 1.229 0 0 0 30 38.984v-10.57a1.229 1.229 0 0 0-1.229-1.229H24.9c.184-4.903 1.482-7.385 3.871-7.385z"
                transform="translate(0 -12.333)"
              />
            </g>
          </svg>

          <div className={styles.testimonialsCard}>
            <p className={styles.testimonialsCardText}>
              <span className={styles.testimonialsCardEmphasis}>
                Within days, Jenga found the right engineer
              </span>{" "}
              to help develop our client web app. He has been working remotely
              in perfect sync with our tech team, with daily slack messages and
              weekly team calls.
            </p>
            <div className={styles.testimonialsCardClient}>
              <img
                src={Cecile}
                alt="ceo of Altaroad"
                className={styles.testimonialsCardImg}
              />
              <div>
                <h3>Cecile Villette</h3>
                <p>CEO, Altaroad</p>
              </div>
            </div>
          </div>

          <div className={styles.testimonialsCard}>
            <p className={styles.testimonialsCardText}>
              The Jenga developer{" "}
              <span className={styles.testimonialsCardEmphasis}>
                integrated smoothly in our team of 20+ software engineers
              </span>{" "}
              to help us deliver on a tight product deadline for our national
              product launch. He was great, so we extended past the launch.
            </p>
            <div className={styles.testimonialsCardClient}>
              <img
                src={Sameer}
                alt="principal engineer at koko networks"
                className={styles.testimonialsCardImg}
              />
              <div>
                <h3>Sameer Soni</h3>
                <p>Principal Engineer, KOKO Networks</p>
              </div>
            </div>
          </div>

          <div className={styles.testimonialsCard}>
            <p className={styles.testimonialsCardText}>
              <span className={styles.testimonialsCardEmphasis}>
                We needed to ramp up
              </span>{" "}
              the front- end development of a data marketing app for a major
              client.{" "}
              <span className={styles.testimonialsCardEmphasis}>
                The two remote Jenga developers worked seamlessly with my team
              </span>{" "}
              and 4 months later, we reduced to one to maintain and improve the
              stabilized app, and shifted the second developer to another
              Artefact project.
            </p>
            <div className={styles.testimonialsCardClient}>
              <img
                src={ClientThree}
                alt="Artefact company logo"
                style={{
                  width: "5rem",
                  height: "5rem",
                  marginRight: "1rem",
                  padding: "0.5rem",
                  background: "white",
                  objectFit: "contain",
                }}
              />
              <div>
                <h3>Partner</h3>
                <p>Artefact</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.buildTeam}>
        <h2 className={styles.buildTeamTitle}>
          Let’s build your tech team together
        </h2>
        <div className={styles.buildTeamCard}>
          <img
            src={ChatStack}
            alt="illustration of two people within boxes talking"
            className={styles.buildTeamCardImg}
          />
          <div className={styles.buildTeamCardContent}>
            <h3 className={styles.buildTeamCardTitle}>
              <div>1</div>
              Chat on Stack
            </h3>
            <p className={styles.buildTeamCardText}>
              We have a 30-minute chat to better understand your tech stack,
              current team setup &amp; desired skillset.
            </p>
          </div>
        </div>
        <img
          src={WavyLineThree}
          alt="Wavy dotted line"
          className={styles.wavyLine}
        />
        <div className={styles.buildTeamCardReverse}>
          <img
            src={SourceTrain}
            alt="Illustration of person holding a magnifying glass to magnify a list with people's profiles"
            className={styles.buildTeamCardImg}
          />
          <div className={styles.buildTeamCardContent}>
            <h3 className={styles.buildTeamCardTitle}>
              <div>2</div>
              Source and Train
            </h3>
            <p className={styles.buildTeamCardText}>
              If not already working for us, we find the right talent and train
              them to fit your stack.
            </p>
          </div>
        </div>
        <img
          src={WavyLineFour}
          alt="Wavy dotted line"
          className={styles.wavyLine}
        />
        <div className={styles.buildTeamCard}>
          <img
            src={HireOnboard}
            alt="Illustration of a person standing next to an enlarged illustrated web page"
            className={styles.buildTeamCardImg}
          />
          <div className={styles.buildTeamCardContent}>
            <h3 className={styles.buildTeamCardTitle}>
              <div>3</div>
              Hire and Onboard
            </h3>
            <p className={styles.buildTeamCardText}>
              Equipped with appropriate communication and development tracking
              tools, our developer seamlessly fits in your team.
            </p>
          </div>
        </div>
        <img
          src={WavyLineThree}
          alt="Wavy dotted line"
          className={styles.wavyLine}
        />
        <div className={styles.buildTeamCardReverse}>
          <img
            src={Deliver}
            alt="Illustration of a man leaning against delivery boxes"
            className={styles.buildTeamCardImg}
          />
          <div className={styles.buildTeamCardContent}>
            <h3 className={styles.buildTeamCardTitle}>
              <div>4</div>
              Deliver
            </h3>
            <p className={styles.buildTeamCardText}>
              Feature after feature, our developer will remotely deliver for
              your product. And you’ll get one simple monthly invoice.
            </p>
          </div>
        </div>

        {/*<img*/}
        {/*  src={WavyLineFour}*/}
        {/*  alt="Wavy dotted line"*/}
        {/*  className={styles.wavyLineContact}*/}
        {/*/>*/}
      </section>

      {/*<section className={styles.contactWrapper} id="contact">*/}
      {/*  <div className={styles.contact}>*/}
      {/*    <div className={styles.contactTitleWrapper}>*/}
      {/*      <h2 className={styles.contactTitle}>Hire Jenga developers</h2>*/}
      {/*      <h2 className={styles.contactTitleEmphasis}>*/}
      {/*        Build your products with us!*/}
      {/*      </h2>*/}
      {/*    </div>*/}

      {/*    <div className={styles.contactForm}>*/}
      {/*      <h3 className={styles.contactFormTitle}>Contact Us</h3>*/}
      {/*      <form*/}
      {/*        className={styles.form}*/}
      {/*        onSubmit={handleSubmit(submitHandler)}*/}
      {/*      >*/}
      {/*        <div className={styles.formGroup}>*/}
      {/*          <label htmlFor="nameID" className={styles.formLabel}>*/}
      {/*            Name*/}
      {/*          </label>*/}
      {/*          <input*/}
      {/*            type="text"*/}
      {/*            name="fullname"*/}
      {/*            id="nameID"*/}
      {/*            placeholder="Your Name"*/}
      {/*            className={styles.formControl}*/}
      {/*            ref={register({ required: "This field is required" })}*/}
      {/*          />*/}
      {/*          <small className={styles.formError}>*/}
      {/*            {errors.fullname?.message}*/}
      {/*          </small>*/}
      {/*        </div>*/}
      {/*        <div className={styles.formGroup}>*/}
      {/*          <label htmlFor="emailID" className={styles.formLabel}>*/}
      {/*            Email*/}
      {/*          </label>*/}
      {/*          <input*/}
      {/*            type="email"*/}
      {/*            name="email"*/}
      {/*            id="emailID"*/}
      {/*            placeholder="Your Email"*/}
      {/*            className={styles.formControl}*/}
      {/*            ref={register({ required: "This field is required" })}*/}
      {/*          />*/}
      {/*          <small className={styles.formError}>*/}
      {/*            {errors.email?.message}*/}
      {/*          </small>*/}
      {/*        </div>*/}
      {/*        <div className={styles.formGroup}>*/}
      {/*          <label htmlFor="reasonID" className={styles.formLabel}>*/}
      {/*            What brings you here today*/}
      {/*          </label>*/}
      {/*          <select*/}
      {/*            name="reason"*/}
      {/*            id="reasonID"*/}
      {/*            placeholder="Choose an Option"*/}
      {/*            className={styles.formControl}*/}
      {/*            ref={register({ required: "This field is required" })}*/}
      {/*          >*/}
      {/*            <option value="">Choose an option</option>*/}
      {/*            <option value="Hire Jenga Developers">*/}
      {/*              Hire Jenga Developers*/}
      {/*            </option>*/}
      {/*            <option value="Other Enquiries">Other Enquiries</option>*/}
      {/*          </select>*/}
      {/*          <small className={styles.formError}>*/}
      {/*            {errors.reason?.message}*/}
      {/*          </small>*/}
      {/*        </div>*/}
      {/*        <div className={styles.formGroup}>*/}
      {/*          <label htmlFor="messageID" className={styles.formLabel}>*/}
      {/*            Message*/}
      {/*          </label>*/}
      {/*          <textarea*/}
      {/*            name="message"*/}
      {/*            id="messageID"*/}
      {/*            cols={30}*/}
      {/*            rows={10}*/}
      {/*            className={styles.formControl}*/}
      {/*            placeholder="Type Message"*/}
      {/*            ref={register({ required: "This field is required" })}*/}
      {/*          ></textarea>*/}
      {/*          <small className={styles.formError}>*/}
      {/*            {errors.message?.message}*/}
      {/*          </small>*/}
      {/*        </div>*/}

      {/*        <Recaptcha*/}
      {/*          sitekey="6LfA8pMUAAAAAMkKegpDmyVDRehCzPMROS1yf8v9"*/}
      {/*          onChange={response => {*/}
      {/*            setToken(response)*/}
      {/*          }}*/}
      {/*          size={["compact"]}*/}
      {/*        />*/}
      {/*        {message && message.length > 0 && (*/}
      {/*          <div*/}
      {/*            className={success ? styles.alertSuccess : styles.alertDanger}*/}
      {/*          >*/}
      {/*            <p className={styles.alertText}>{message}</p>*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*        <button*/}
      {/*          className={styles.contactSubmit}*/}
      {/*          disabled={loading || token.length === 0}*/}
      {/*          title="Please verify that you are not a robot"*/}
      {/*        >*/}
      {/*          {loading ? "Sending..." : "Submit Form"}*/}
      {/*        </button>*/}
      {/*      </form>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </Layout>
  )
}

export default IndexPage
